
import React from 'react';
import Reservations from '../Reservations/Reservations.js';
import Form from '../Form/Form';
import { Button } from 'antd';
import Reservation from '../Reservation/Reservation';
import { isBrowser } from 'react-device-detect';
import moment from 'moment';
import {PlusCircleOutlined, ArrowLeftOutlined} from "@ant-design/icons";
import browserClasses from './Room.module.css';
import mobileClasses from './Mobile/Room.module.css';

const classes = isBrowser ? browserClasses : mobileClasses;

const Room = ({ room, startDate, endDate, reservations, close }) => {

    const [showForm, setShowForm] = React.useState(false);
    const [selectedReservation, setSelectedReservation] = React.useState(null);

    const allPeople = reservations.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.people
    }, 0);

    const available = room.capacity - allPeople;

    const handleClick = () => {
        if (available > 0) openForm(true);
        else return alert('Няма свободни места!');
    }

    const openForm = () => {
        setShowForm(true);
    }

    const closeForm = () => {
        setShowForm(false);
    }

    if (showForm) {
        return <Form allPeople={allPeople} type="add" close={closeForm} startDate={startDate} endDate={endDate} room={room} />
    }

    if (selectedReservation) {
        return <Reservation allPeople={allPeople} room={room} reservation={selectedReservation} close={() => setSelectedReservation(null)} />
    }

    return <div className={classes.Container}>
            <div style={isBrowser ? {} : { display: "flex", justifyContent: "space-between", padding: '20px 10px' }}>
                <Button type="primary" style={isBrowser ? { margin: '40px 5px' } : { margin: '0 10px' }} onClick={handleClick}><PlusCircleOutlined />Добави </Button>
                <Button type="primary" style={isBrowser ? { margin: '40px 5px' } : { margin: '0 10px' }} onClick={close}><ArrowLeftOutlined />Назад</Button>
            </div>
            <div style={{ display: "flex", justifyContent: "center", padding: 5, margin: 5 }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <h3 style={isBrowser ? { color: '#353535', padding: 20 } : { textAlign: "center" }}>
                        Стая: {room.number}, Места: {room.capacity}
                    </h3>
                    <h4 style={{ textAlign: "center" }}>{moment(startDate).format("DD") + " - " + moment(endDate).format("DD MMMM YYYY")}</h4>
                </div>
            </div>
        <Reservations reservations={reservations} setSelectedReservation={setSelectedReservation} />
    </div>
};

export default Room;