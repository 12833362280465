import React from 'react';
import { Button, Modal } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import classes from './Login.module.css';

const Login = ({ handleAuthClick }) => (
    <Modal visible={true} closable={false}
        cancelButtonProps={{ className: classes.Hidden }}
        okButtonProps={{ className: classes.Hidden }}
    >
        <div className={classes.Container}>
            <img className={classes.Logo} src="/img/logo.jpg" alt="logo" height="100px" />
            <div className={classes.Controls}>
                <Button type="primary" className={classes.AuthBtn}
                    onClick={handleAuthClick}>Влез с Google <GoogleOutlined /></Button>
            </div>
        </div>
    </Modal>
);

export default Login;