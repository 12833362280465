const rooms = [
    {
        name: 'Голяма Хижа',
        floors: [
            {
                name: '2ри етаж',
                rooms: [
                    [
                        { id: "G01", number: '1', capacity: 4 },
                        { id: "EMPTY1" },
                        { id: "G03", number: '3', capacity: 2 },
                        { id: "G04", number: '4', capacity: 2 },
                        { id: "G05", number: '5', capacity: 2 },
                        { id: "G07", number: '7', capacity: 2 },
                        { id: "G08", number: '8', capacity: 2 },
                        { id: "G09", number: '9', capacity: 2 },
                        { id: "G10", number: '10', capacity: 7 },
                    ],
                    [
                        { id: "G16", number: '16', capacity: 5 },
                        { id: "G15", number: '15', capacity: 4 },
                        { id: "G14", number: '14', capacity: 4 },
                        { id: 'EMPTY2'},
                        { id: 'EMPTY3'},
                        { id: 'EMPTY4'},
                        { id: "G12", number: '12', capacity: 9 },
                        { id: 'EMPTY5'},
                        { id: "G11", number: '11', capacity: 3 },
                    ]
                ]
            },
            {
                name: '3ти етаж',
                rooms: [
                    [
                        { id: "G17", number: '17', capacity: 4 },
                        { id: "G18", number: '18', capacity: 3 },
                        { id: "G19", number: '19', capacity: 3 },
                        { id: "G20", number: '20', capacity: 2 },
                        { id: "G21", number: '21', capacity: 2 },
                        { id: "G22", number: '22', capacity: 2 },
                        { id: 'EMPTY6'},
                        { id: 'EMPTY7'},
                        { id: 'EMPTY8'},
                    ],
                    [
                        { id: "G25", number: '25', capacity: 5 },                        
                        { id: 'EMPTY9'},
                        { id: 'EMPTY10'},
                        { id: 'EMPTY11'},
                        { id: 'EMPTY12'},
                        { id: 'EMPTY13'},
                        { id: 'EMPTY14'},
                        { id: 'EMPTY15'},
                        { id: 'EMPTY16'},
                    ]
                ],
            },
            {
                name: '4ти етаж',
                rooms: [
                    [
                        { id: "G26", number: '26', capacity: 5 },
                        { id: "G27", number: '27', capacity: 3 },
                        { id: "G28", number: '28', capacity: 7 },
                        { id: 'EMPTY17'},
                        { id: 'EMPTY18'},
                        { id: 'EMPTY19'},
                        { id: 'EMPTY20'},
                        { id: 'EMPTY21'},
                        { id: 'EMPTY22'},
                    ],
                    [
                        { id: "G29", number: '29', capacity: 6 },
                        { id: 'EMPTY23'},
                        { id: 'EMPTY24'},
                        { id: 'EMPTY25'},
                        { id: 'EMPTY26'},
                        { id: 'EMPTY27'},
                        { id: 'EMPTY28'},
                        { id: 'EMPTY29'},
                        { id: 'EMPTY30'},
                    ]
                ],
            },
        ]
    },
    {
        name: 'Малка Хижа',
        floors: [
            {
                name: '1ви етаж',
                rooms: [
                    [
                        { id: "M01", number: '1', capacity: 2 },
                        { id: "M02", number: '2', capacity: 2 },
                        { id: "M03", number: '3', capacity: 2 },
                        { id: "M04", number: '4', capacity: 2 },
                        { id: 'EMPTY31'},
                        { id: 'EMPTY32'},
                        { id: 'EMPTY33'},
                        { id: 'EMPTY34'},
                        { id: 'EMPTY35'},
                    ]
                ]
            },
            {
                name: '2ри етаж',
                rooms: [
                    [
                        { id: "M07", number: '7', capacity: 2 },
                        { id: "M09", number: '9', capacity: 4 },
                        { id: "M11", number: '11', capacity: 2 },
                        { id: "M13", number: '13', capacity: 2 },
                        { id: "M15", number: '15', capacity: 2 },
                        { id: "A1", number: 'A1', capacity: 3 },
                        { id: 'EMPTY37'},
                        { id: 'EMPTY38'},
                        { id: 'EMPTY39'},
                    ],
                    [
                        { id: "M06", number: '6', capacity: 7 },
                        { id: "M08", number: '8', capacity: 4 },
                        { id: "M10", number: '10', capacity: 4 },
                        { id: "M12", number: '12', capacity: 4 },
                        { id: "M14", number: '14', capacity: 5 },
                        { id: "A2", number: 'A2', capacity: 4 },
                        { id: 'EMPTY40'},
                        { id: 'EMPTY41'},
                        { id: 'EMPTY42'},
                    ]
                ]
            }
        ]
    }
];

export default rooms;