import { Button } from 'antd';
import React from 'react';
import classes from './Reservations.module.css';

const Reservations = ({ reservations, setSelectedReservation }) => {
    return (
        <ul className={classes.List}>
            {reservations && reservations.length
                ? reservations.map((reservation, key) => (
                    <li className={classes.ListItem} key={key}>
                        <span>{reservation.summary}, {reservation.people} {reservation.people > 1 ? 'души' : 'човек'}</span><br />
                        <Button type="link" onClick={() => setSelectedReservation(reservation)}>Виж повече</Button>
                    </li>
                ))
                : <li className={classes.ListItem}>
                    Няма резервации
                </li>
            }
        </ul>
    );
}

export default Reservations;