import React from 'react';
import {Spin} from 'antd';
import classes from './Loader.module.css';

const Loader = () => (
    <div className={classes.Container}>
        <Spin />
    </div>
);

export default Loader;