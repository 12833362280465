import React from "react";
import ReactDOM from 'react-dom';
import moment from 'moment';
import { DatePicker, Alert } from 'antd';
import { isBrowser } from "react-device-detect";
import 'moment/locale/bg';
import locale from 'antd/es/date-picker/locale/bg_BG';

import Expire from '../Expire/Expire';
import * as constants from '../../constants';
import Room from '../Room/Room';
import roomsSchema from '../../config/roomsSchema';
import browserClasses from './Rooms.module.css';
import mobileClasses from './Mobile/Rooms.module.css';
import Loader from '../Loader/Loader';

const { RangePicker } = DatePicker;

const classes = isBrowser ? browserClasses : mobileClasses;

const Rooms = () => {

    const [selectedRoom, setSelectedRoom] = React.useState(null);
    const [selectedRoomReservations, setSelectedRoomReservations] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const [startDate, setStartDate] = React.useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
    const [events, setEvents] = React.useState(null);

    const transformEvents = events => {

        return events.map(event => {

            const { id, summary, description, location, start, end } = event;

            if (description && /^\{.*\}$/.test(description)) {
                try {

                    const { room, people, phone, other } = JSON.parse(description);

                    return {
                        id,
                        summary,
                        location,
                        start,
                        end,
                        room,
                        people,
                        phone,
                        other
                    };

                } catch (err) {
                    console.error(err);
                    return null;
                }
            }
        }).filter(x => !!x);
    }

    const getEvents = () => {
        window.gapi.client.calendar.events

            .list({
                calendarId: constants.calendarId,
                showDeleted: false,
                singleEvents: true,
                // maxResults: 10,
                orderBy: 'startTime'
            })

            .then(function (response) {
                setEvents(transformEvents(response.result.items));
                setLoading(false);
                ReactDOM.render(<div style={{ margin: 20 }}>
                    <Expire delay={5000}>
                        <Alert message={`[${moment().format('YYYY-MM-DD HH:mm')}] - Успешно синхронизиран календар!`} type="success" />
                    </Expire>
                </div>, document.getElementById('alert'));
                console.log('[Rooms.js] success: ', response.result.items);
            })

            .catch(err => {
                ReactDOM.render((
                    <div style={{ margin: 20 }}>
                        <Expire delay={5000}>
                            <Alert message="Данните за стаите не се заредиха успешно" type="error" />
                        </Expire>
                    </div>
                ), document.getElementById('modal-alert'));

                console.error('[Rooms.js] error: ', err);
            });
    }

    React.useEffect(() => {
        getEvents();
    }, []);

    const handleRoomClick = (room, roomReservations) => {
        setSelectedRoom(room);
        setSelectedRoomReservations(roomReservations);
    };

    const closeRoom = () => {
        setSelectedRoom(null);
        setSelectedRoomReservations(null);
    }

    if (loading) {
        return <Loader />
    }

    if (selectedRoom && selectedRoomReservations) {
        return <Room
            startDate={startDate}
            endDate={endDate}
            room={selectedRoom}
            reservations={selectedRoomReservations}
            close={closeRoom}
        />
    }

    const reservations = [];


    if (events && startDate && endDate) {

        const tempStartDate = moment(startDate);
        const tempEndDate = moment(endDate);

        events.forEach(event => {
            if (event?.start?.date && event?.end?.date) {

                const start = moment(event.start.date);
                const end = moment(event.end.date);

                if (
                    (start.isSameOrAfter(tempStartDate) && start.isSameOrBefore(tempEndDate))
                    ||
                    (end.isSameOrAfter(tempStartDate) && end.isSameOrBefore(tempEndDate))
                    ||
                    (start.isBefore(tempStartDate) && end.isAfter(tempEndDate))
                ) {
                    reservations.push(event);
                }
            }
        });
    }

    console.log('events', events);
    console.log('reservations', reservations);

    return (
        <div className={classes.Container}>
            <div style={{ margin: isBrowser ? 20 : '0 0 40px 0' }}>
                <RangePicker
                    locale={locale}
                    inputReadOnly={true}
                    defaultValue={[moment(), moment()]}
                    defaultPickerValue={[moment(), moment()]}
                    onChange={dates => {
                        if (dates && Array.isArray(dates) && dates.length === 2);
                        const [start, end] = dates;
                        setStartDate(start.format("YYYY-MM-DD"));
                        setEndDate(end.format("YYYY-MM-DD"));
                    }}
                    format={"DD MMMM YYYY"}
                    ranges={{
                        'Днес': [moment(), moment()],
                        'Уикенд': [moment().endOf('week').subtract(1, "days"), moment().endOf('week')],
                        'Седмица': [moment().startOf('week'), moment().endOf('week')],
                        'Месец': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    style={{ width: '100%' }}
                />
            </div>
            {roomsSchema.map((building, key) => (

                <div key={key} className={classes.Building}>
                    <h2 className={classes.Title}>{building.name}</h2>
                    {building.floors.map((floor, key) => (

                        <div className={classes.Floor} key={key}>
                            <h3 className={classes.Subtitle}>{floor.name}</h3>
                            <div className={classes.Rooms}>
                                {floor.rooms.map((row, key) => (

                                    <div className={classes.Row} key={key}>
                                        {row.map(room => {

                                            const roomReservations = reservations.filter(x => x.room === room.id);

                                            const people = roomReservations
                                                .reduce(function (accumulator, currentValue) {
                                                    return accumulator + currentValue.people
                                                }, 0);

                                            let roomStatus = '';

                                            if (people === 0) {
                                                roomStatus = classes.Empty;
                                            }
                                            else if (people >= room.capacity) {
                                                roomStatus = classes.Full;
                                            }
                                            if (people > 0 && people < room.capacity) {
                                                roomStatus = classes.Half;
                                            }

                                            return room.number
                                                ? <div
                                                    key={room.id}
                                                    className={`${classes.Room} ${roomStatus}`}
                                                    onClick={() => handleRoomClick(room, roomReservations)}>
                                                    <div className={classes.Number}>
                                                        <span>{room.number}</span>
                                                    </div>
                                                    <div className={classes.Capacity}>
                                                        <span>{room.capacity}</span>
                                                    </div>
                                                </div>
                                                : <div key={room.id} className={classes.Blank}></div>
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Rooms;