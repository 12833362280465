import React from 'react';
import Rooms from '../Rooms/Rooms';
import classes from "./App.module.css";
import Login from '../Login/Login';
import Loader from '../Loader/Loader';

var CLIENT_ID = '715696021428-2rls2pvup3oa1186hrnka4ek9pvns119.apps.googleusercontent.com';
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
var SCOPES = "https://www.googleapis.com/auth/calendar.events";

const App = () => {

    const [showAuthButton, setShowAuthButton] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [showRooms, setShowRooms] = React.useState(false);

    const handleAuthClick = () => {
        window.gapi.auth2.getAuthInstance().signIn();
    }

    // const handleSignoutClick = () => {
    //     window.gapi.auth2.getAuthInstance().signOut();
    // }

    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            setShowAuthButton(false);
            setShowRooms(true);
            setLoading(false);
        } else {
            setShowRooms(false);
            setShowAuthButton(true);
            setLoading(false);
        }
    }

    const initClient = () => {
        window.gapi.client.init({
            discoveryDocs: DISCOVERY_DOCS,
            clientId: CLIENT_ID,
            scope: SCOPES
        }).then(function () {

            // Listen for sign-in state changes.
            window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

            // Handle the initial sign-in state.
            updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
        });
    }

    const handleClientLoad = () => {
        window.gapi.load('client:auth2', initClient);
    }

    React.useEffect(() => {
        handleClientLoad();
        /* eslint-disable */
    }, []);

    if (loading) {
        return <Loader />
    }

    return (
        <div className={classes.Container}>
            {showAuthButton && <Login handleAuthClick={handleAuthClick} /> }
            {/* <Button type="primary" danger className={classes.SignOutBtn} onClick={handleSignoutClick}>  Изход</Button> */}
            {showRooms ? <Rooms /> : null}
        </div>
    )
}

export default App;