import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { isBrowser } from 'react-device-detect';
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined} from '@ant-design/icons';

import Form from '../Form/Form';
import browserClasses from './Reservation.module.css';
import mobileClasses from './Mobile/Reservation.module.css';

const classes = isBrowser ? browserClasses : mobileClasses;

const Reservation = ({ allPeople, room, reservation, close }) => {

    const [openEdit, setOpenEdit] = React.useState(false);

    const deleteReservation = id => {

        if (window.confirm("Сигурни ли сте че искате да изтриете този запис?")) {
            if (window?.gapi?.client?.calendar?.events) {

                var request = window.gapi.client.calendar.events.delete({
                    'calendarId': 'primary',
                    'eventId': id
                });

                request.execute(function (resp) {
                    alert("Успешно изтрита резервация!");
                    window.location.reload();
                });
            }
        }
        else return;
    }

    if (openEdit) {
        return <Form allPeople={allPeople} room={room} type="edit"
            peopleBeforeUpdate={reservation.people} eventId={reservation.id} 
            initialValues={reservation} close={() => setOpenEdit(false)} />
    }

    return <div className={classes.Container}>
            <div className={classes.Back}>
                <Button type="primary" onClick={() => setOpenEdit(true)} style={{  }}><EditOutlined/>Редактирай</Button>
                <Button type="primary" onClick={close}><ArrowLeftOutlined />Назад</Button>
            </div>
            <div className={classes.Details}>
                <table className={classes.Table}>
                    <tbody>
                        <tr>
                            <td><b>Име:</b></td>
                            <td><i>{reservation.summary}</i></td>
                        </tr>
                        <tr>
                            <td><b>Град:</b></td>
                            <td><i>{reservation.location}</i></td>
                        </tr>
                        <tr>
                            <td><b>Телефон:</b></td>
                            <td><i>{reservation.phone}</i></td>
                        </tr>
                        <tr>
                            <td><b>Брой хора:</b></td>
                            <td><i>{reservation.people} {reservation.people === 1 ? 'човек' : 'души'}</i></td>
                        </tr>
                        <tr>
                            <td><b>От:</b></td>
                            <td><i>{moment(reservation.start.date).format('YYYY-MM-DD')}</i></td>
                        </tr>
                        <tr>
                            <td><b>До:</b></td>
                            <td><i>{moment(reservation.end.date).format('YYYY-MM-DD')} (Включително)</i></td>
                        </tr>
                        <tr>
                            <td><b>Описание:</b></td>
                            <td><i>{reservation.other || ''}</i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={classes.Controls}>
                <Button type="primary" onClick={() => deleteReservation(reservation.id)} style={{ width: "100%" }} danger><DeleteOutlined />Изтрий</Button>
            </div>
        </div>
}

export default Reservation;