import React from 'react';
import { Alert, Button } from 'antd';
import moment from 'moment';
import { withTheme } from '@rjsf/core';
import { Theme as AntdTheme } from "@rjsf/antd";
import { isBrowser } from 'react-device-detect';
import { SaveOutlined, FormOutlined, CopyOutlined, SnippetsOutlined, CloseCircleOutlined } from "@ant-design/icons";
import schema from '../../config/formSchema';

const Form = withTheme(AntdTheme);

const CustomForm = ({
    type = "add",
    initialValues = {},
    eventId,
    allPeople,
    peopleBeforeUpdate,
    room,
    startDate,
    endDate,
    close
}) => {

    const formRef = React.useRef(null);
    const [pastedValues, setPastedValues] = React.useState();

    const copy = () => {

        const formData = formRef?.current?.state?.formData;
        navigator.clipboard.writeText(JSON.stringify(formData))
            .then(() => {
                console.log('Copied to clipboard');
            })
            .catch(err => {
                console.error('Could not copy to clipboard: ', err);
            });
    }

    const paste = () => {
        navigator.clipboard.readText().then(text => {
            setPastedValues(JSON.parse(text));
        });
    }

    const insertEvent = (resource, people) => {

        const occupations = parseInt(allPeople) + parseInt(people);
        const available = parseInt(room.capacity) - parseInt(occupations);

        if (available > -1) {
            if (window?.gapi?.client?.calendar?.events) {
                var request = window.gapi.client.calendar.events.insert({
                    'calendarId': 'primary',
                    resource
                });

                request.execute(function (resp) {
                    alert("Успешно създадена резервация!");
                    window.location.reload();
                });
            }
        }
        else {
            alert("Няма достатъчно места!");
        }
    }

    const patchEvent = (resource, people) => {

        const occupations = parseInt(allPeople) - parseInt(peopleBeforeUpdate) + parseInt(people);
        const available = parseInt(room.capacity) - parseInt(occupations);

        if (available > -1) {
            if (window?.gapi?.client?.calendar?.events) {
                var request = window.gapi.client.calendar.events.patch({
                    'calendarId': 'primary',
                    'eventId': eventId,
                    'resource': resource
                });

                request.execute(function (event) {
                    alert("Успешно редактирана резервация!");
                    window.location.reload();
                });
            }
        }
        else {
            alert("Няма достатъчно места!");
        }
    }

    const onFinish = ({ formData }) => {

        const { summary, location, people, phone, other } = formData;

        if (!people || people < 1) {
            alert("Броят на гостите е задължителен!");
            return;
        }

        if (people > room.capacity) {
            alert("Надвишен брой хора!");
            return;
        }

        var resource = {
            summary,
            start: {
                date: type === "edit" ? initialValues.start.date : startDate,
            },
            end: {
                date: type === "edit" ? initialValues.end.date : endDate,
            },
            location: location || "",
            description: JSON.stringify({
                room: type === "edit" ? initialValues.room : room.id,
                people,
                phone: phone || "",
                other: other || ""
            })
        };

        if (type === "edit") {
            patchEvent(resource, people);
        }
        else if (type === "add") {
            insertEvent(resource, people);
        }
    }

    const onFinishFailed = errorInfo => {
        alert("Моля попълнете всички задължителни полета!")
        console.log('Failed:', errorInfo);
    };

    return <div>
        <div style={{ display: "flex", justifyContent: "center", padding: 5, margin: 5 }}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <h2>{type === "edit" ? "Редактирай резервация" : "Направи резервация"}</h2>
                <h3 style={isBrowser ? { color: '#353535', padding: 20 } : { textAlign: "center" }}>
                    Стая: {room.number}, Места: {room.capacity}
                </h3>
                <h4 style={{ textAlign: "center" }}>{moment(startDate).format("DD") + " - " + moment(endDate).format("DD MMMM YYYY")}</h4>
            </div>
        </div>

        <Button style={isBrowser ? { width: '50%', margin: "20px 0" } : { width: '50%', margin: "20px 0" }} type="dashed" onClick={copy}><CopyOutlined />Copy </Button>
        <Button style={isBrowser ? { width: '50%', margin: "20px 0" } : { width: '50%', margin: "20px 0" }} type="dashed" onClick={paste} ><SnippetsOutlined />Paste </Button>
        <Form
            ref={formRef}
            schema={schema}
            formData={pastedValues || initialValues || {}}
            onSubmit={onFinish}
            onError={onFinishFailed}
            showErrorList={false}
        >
            <Button style={{ width: '50%', marginBottom: 50 }} type="primary" htmlType="submit"> {type === "edit" ? <span><SaveOutlined />{" Запази"}</span> : <span><FormOutlined /> Създай</span>} </Button>
            <Button style={{ width: '50%', marginBottom: 50 }} htmlType="button" onClick={close}><CloseCircleOutlined />Откажи </Button>
        </Form>
    </div>
}

export default CustomForm;