const getSchema = {
    title: "",
    type: "object",
    properties: {
        summary: {
            type: "string",
            title: "Имена"
        },
        location: {
            type: "string",
            title: "Град"
        },
        phone: {
            type: "string",
            title: "Номер"
        },
        people: {
            type: "number",
            title: "Брой гости",
            minimum: 0
        },
        other: {
            type: "string",
            title: "Подробности"
        }
    },
    required: ["summary", "people"]
};

export default getSchema;